import React from "react"
import Layout from "../components/Layout"
// import PageHeader from "../components/PageHeader"
import ProductCard from "../components/ProductCard"
import Heading from "../components/Heading"
import SEO from "../components/SEO"
import { graphql, useStaticQuery } from "gatsby"

const pageQuery = graphql`
  {
    products: allGraphCmsProduct {
      nodes {
        name
        slug
        price
        category {
          name
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 450, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default function Products() {
  const { products } = useStaticQuery(pageQuery)
  return (
    <Layout>
      <SEO
        meta={{
          title: "Hallowed Hollows | Products",
          description: "Hallowed Hollows Products Page",
        }}
      />
      <section className="text-gray-700 body-font">
        <div className="px-5">
          <Heading textAlign="left">Products</Heading>
        </div>
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {products &&
              products.nodes.map((product, i) => (
                <ProductCard key={i} product={product} />
              ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}
