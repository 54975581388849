import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default function ProductCard({ product }) {
  const {
    name,
    // category: { name: categoryName = "" },
    image,
    price,
    slug,
  } = product

  return (
    <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
      <div className="block relative h-48 rounded overflow-hidden">
        <Link to={`/products/${slug}`}>
          {/* <img
            alt="ecommerce"
            className="object-cover object-center w-full h-full block"
            src={image[0] ? image[0].url : "https://dummyimage.com/420x260"}
          /> */}
          {image[0]?.localFile ? (
            <Img
              alt={`${name} thumbnail`}
              fluid={image[0].localFile.childImageSharp.fluid}
              className="object-cover object-center w-full h-full block"
            ></Img>
          ) : (
            <img
              alt={`${name} thumbnail`}
              className="object-cover object-center w-full h-full block"
              src={"https://dummyimage.com/420x260"}
            />
          )}
        </Link>
      </div>
      <div className="mt-4">
        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
          {/* {categoryName} */}
        </h3>
        <h2 className="text-gray-900 title-font text-lg font-medium">{name}</h2>
        {/* <p className="mt-1">${price}</p> */}
      </div>
    </div>
  )
}
